import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { URLS } from "../urls";
import DatePicker from "react-datetime";
import { branch_id } from "../../utils";
import Select from "react-select";
import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";
import moment from "moment";

function Overtime({ allowance }) {
  const [status, setStatus] = useState("");
  const [showAddOvertimeModal, setShowAddOvertimeModal] = useState(false);
  const [overtimes, setOvertimes] = useState([]);
  const [overtimeIdToDelete, setOvertimeIdToDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    status: "",
  });

  const getOvertimes = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendOvertime}/get`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setOvertimes([...result.overtimes]);
          resolve();
        })
        .catch((err) => {
          toast("Unable to get, please refresh your browser", {
            type: "error",
          });
          reject();
        });
    });
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const resolvedPromises = await Promise.all([getOvertimes()]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUp();
    return () => {};
  }, []);

  const deleteOvertime = () => {
    window.$("#delete_overtime").modal("toggle");
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendOvertime}/delete/${overtimeIdToDelete}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = overtimes.filter(
            (overtime) => overtime._id !== overtimeIdToDelete
          );
          setOvertimes(removedDeleted);
          toast("Deleted", {
            type: "success",
          });
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to delete", {
          type: "error",
        });
        setLoading(false);
      });
  };

  const changeStatus = ({ overtimeId, status }) => {
    const token = "dummy token";
    setLoading(true);
    fetch(`${URLS.backendOvertime}/change-status`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ overtimeId, status }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const updatedOvertimes = overtimes.map((el) => {
            if (el._id === overtimeId) {
              return { ...el, status };
            }
            return el;
          });
          setOvertimes(updatedOvertimes);
          toast("Status changed", {
            type: "success",
          });
        } else {
          throw Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to change status", {
          type: "error",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-8 col-6">
                  <h4 className="page-title">Allowance Application</h4>
                </div>
                <div className="col-sm-4 col-6 text-right m-b-30">
                  <button
                    className="btn add-btn"
                    onClick={() => setShowAddOvertimeModal(true)}
                  >
                    <i className="fa fa-plus"></i> Apply
                  </button>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Overtime Statistics -->*/}
              <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                  <div className="stats-info">
                    <h6>Employee</h6>
                    <h4>
                      ... <span>this month</span>
                    </h4>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                  <div className="stats-info">
                    <h6>Hours</h6>
                    <h4>
                      ... <span>this month</span>
                    </h4>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                  <div className="stats-info">
                    <h6>Pending Request</h6>
                    <h4>...</h4>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                  <div className="stats-info">
                    <h6>Rejected</h6>
                    <h4>...</h4>
                  </div>
                </div>
              </div>
              {/*<!-- /Overtime Statistics -->*/}
              <hr />
              {/*<!-- Search Filter -->*/}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <input type="text" className="form-control floating" />
                    <label className="focus-label">Employee Name</label>
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <Form.Control
                      as="select"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option> -- Select -- </option>
                      <option> Pending </option>
                      <option> Approved </option>
                      <option> Rejected </option>
                    </Form.Control>
                    <label className="focus-label">Overtime Status</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <DatePicker
                        value={filter.startDate}
                        dateFormat="DD MMM, YYYY"
                        onChange={(date) => {
                          setFilter({
                            ...filter,
                            startDate: date,
                          });
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </div>
                    <label className="focus-label">From</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <DatePicker
                        value={filter.endDate}
                        dateFormat="DD MMM, YYYY"
                        onChange={(date) => {
                          setFilter({
                            ...filter,
                            endDate: date,
                          });
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </div>
                    <label className="focus-label">To</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <button className="btn add-btn btn-block"> Search </button>
                </div>
              </div>
              {/*<!-- /Search Filter -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>Employee</th>
                          <th>Overtime</th>
                          <th>Date</th>
                          <th>Hours</th>
                          <th>Description</th>
                          <th className="text-center">Status</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {overtimes?.length
                          ? overtimes.map((overtime) => {
                              const { employee } = overtime;
                              return (
                                <tr>
                                  <td>
                                    <h2 className="table-avatar blue-link">
                                      <Link
                                        to={`profile/${employee?._id}`}
                                        className="avatar"
                                      >
                                        <img
                                          alt=""
                                          src="assets/img/profiles/avatar-02.jpg"
                                        />
                                      </Link>
                                      <Link to={`profile/${employee?._id}`}>
                                        {employee?.personalInfo.name.first +
                                          " " +
                                          employee?.personalInfo.name.last}
                                      </Link>
                                    </h2>
                                  </td>
                                  <td>{overtime?.allowance?.name}</td>
                                  <td>
                                    {overtime?.overtimeDate
                                      ? moment(overtime?.overtimeDate).format(
                                          "DD MMM, YYYY"
                                        )
                                      : "..."}
                                  </td>
                                  <td>
                                    {overtime.hours > 1
                                      ? overtime.hours + " hours"
                                      : overtime.hours + " hour"}
                                  </td>
                                  <td>{overtime.description}</td>
                                  <td className="text-center">
                                    <Dropdown className="action-label">
                                      <Dropdown.Toggle
                                        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                        variant="btn-white"
                                      >
                                        {overtime.status === "New" ? (
                                          <i className="fa fa-dot-circle-o text-purple"></i>
                                        ) : overtime.status === "Pending" ? (
                                          <i className="fa fa-dot-circle-o text-info"></i>
                                        ) : overtime.status === "Approved" ? (
                                          <i className="fa fa-dot-circle-o text-success"></i>
                                        ) : (
                                          <i className="fa fa-dot-circle-o text-danger"></i>
                                        )}
                                        {overtime.status}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu
                                        popperConfig={{
                                          strategy: "fixed",
                                        }}
                                        renderOnMount
                                        className="dropdown-with-icons"
                                      >
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() =>
                                            changeStatus({
                                              overtimeId: overtime._id,
                                              status: "New",
                                            })
                                          }
                                        >
                                          <i className="fa fa-dot-circle-o text-purple"></i>
                                          New
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() =>
                                            changeStatus({
                                              overtimeId: overtime._id,
                                              status: "Pending",
                                            })
                                          }
                                        >
                                          <i className="fa fa-dot-circle-o text-info"></i>
                                          Pending
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() =>
                                            changeStatus({
                                              overtimeId: overtime._id,
                                              status: "Approved",
                                            })
                                          }
                                        >
                                          <i className="fa fa-dot-circle-o text-success"></i>
                                          Approved
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() =>
                                            changeStatus({
                                              overtimeId: overtime._id,
                                              status: "Declined",
                                            })
                                          }
                                        >
                                          <i className="fa fa-dot-circle-o text-danger"></i>
                                          Declined
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td className="text-right">
                                    <div className="dropdown dropdown-action">
                                      <a
                                        href="javaScript:void(0);"
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        {/*  <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                          data-toggle="modal"
                                          data-target="#edit_leave"
                                        >
                                          <i className="fa fa-pencil m-r-5"></i>{" "}
                                          Edit
                                        </a> */}
                                        <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                          data-toggle="modal"
                                          data-target="#delete_overtime"
                                          onClick={() => {
                                            setOvertimeIdToDelete(overtime._id);
                                          }}
                                        >
                                          <i className="fa fa-trash-o m-r-5"></i>{" "}
                                          Delete
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>

          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
      {/*<!-- Delete Overtime Modal -->*/}
      <div
        className="modal custom-modal fade"
        id="delete_overtime"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Overtime</h3>
                <p>Are you sure want to Delete this?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <a
                      href="javascript:void(0);"
                      className="btn btn-danger continue-btn"
                      onClick={() => deleteOvertime()}
                    >
                      Delete
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="javascript:void(0);"
                      data-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- /Delete Overtime Modal -->*/}

      {showAddOvertimeModal && (
        <AddOvertimeModal
          allowance={allowance}
          setShowAddOvertimeModal={setShowAddOvertimeModal}
          refetch={() => setUp()}
        />
      )}
      <ModalLoader show={loading} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    // User: state.api.User,
    deductionSettings: state.deductionSettings.deductions,
    bonusSettings: state.bonusSettings.bonus,
    allowance: state.allowanceReducer.allowances,
    benefit: state.BenefitReducer.benefits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Overtime);

function AddOvertimeModal({
  allowance = [],
  setShowAddOvertimeModal,
  refetch,
}) {
  const [state, setState] = useState({
    overtimeDate: new Date(),
    hours: "",
    employeeId: "",
    description: "",
    allowanceId: "",
  });
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [mapEmployees, setMapEmployees] = useState([]);
  const getEmployees = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const date = new Date();

          const emp = result.employees.map((em) => ({
            ...em,
            label: `${
              em.personalInfo.name.first ? em.personalInfo.name.first : ""
            } ${
              em.personalInfo.name.middle ? em.personalInfo.name.middle : ""
            } ${em.personalInfo.name.last ? em.personalInfo.name.last : ""}`,
            value: em.userId,
          }));

          setEmployees(result.employees);
          setMapEmployees(emp);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const resolvedPromises = await Promise.all([getEmployees()]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUp();
    return () => {};
  }, []);

  const allowanceForSelectedUser = useMemo(() => {
    return allowance
      .filter((el) => el)
      .map((el) => ({ ...el, label: el.name, value: el._id }));
  }, [state?.employeeId, allowance]);

  const submit = (e) => {
    e.preventDefault();
    console.log("in submit");
    setLoading(true);

    const token = "dummy token";
    fetch(`${URLS.backendOvertime}/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ overtime: state }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Success", {
            type: "success",
          });
          refetch();
          setShowAddOvertimeModal(false);
        } else {
          const e = Error(result.err);
          console.log(e);
        }
      })
      .catch((err) => {
        toast("Error unable to perform  action", {
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className="modal custom-modal fade"
      show={true}
      centered
      onHide={() => setShowAddOvertimeModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={submit}>
          <div className="form-group">
            <label>
              Select Employee <span className="text-danger">*</span>
            </label>
            {mapEmployees && (
              <Select
                key={state.employeeId}
                closeMenuOnSelect={true}
                isSearchable
                required
                classNamePrefix="form-control"
                value={mapEmployees?.find(
                  (el) => el.value === state.employeeId
                )}
                onChange={(selected) => {
                  setState({
                    ...state,
                    employeeId: selected?.value || "",
                  });
                }}
                options={mapEmployees}
                isClearable
              />
            )}
          </div>

          <div className="form-group">
            <label>
              Allowance <span className="text-danger">*</span>
            </label>
            <Select
              key={state.allowanceId}
              closeMenuOnSelect={true}
              isSearchable
              required
              classNamePrefix="form-control"
              value={allowanceForSelectedUser?.find(
                (el) => el.value === state.allowanceId
              )}
              onChange={(selected) => {
                setState({
                  ...state,
                  allowanceId: selected?.value || "",
                });
              }}
              options={allowanceForSelectedUser}
              isClearable
            />
          </div>

          <div className="form-group">
            <label>
              Overtime Date <span className="text-danger">*</span>
            </label>
            <div className="cal-icon">
              <DatePicker
                value={state.overtimeDate}
                dateFormat="DD MMM, YYYY"
                onChange={(date) => {
                  setState({
                    ...state,
                    overtimeDate: date,
                  });
                }}
                closeOnSelect={true}
                timeFormat={false}
              />
            </div>
          </div>
          <div className="form-group">
            <label>
              Overtime Hours <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="number"
              value={state.hours}
              onChange={(e) => {
                setState({
                  ...state,
                  hours: e.target.value,
                });
              }}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              rows="4"
              className="form-control"
              onChange={(e) => {
                setState({
                  ...state,
                  description: e.target.value,
                });
              }}
            >
              {state.description}
            </textarea>
          </div>
          <div className="submit-section">
            <button className="btn btn-primary submit-btn" disabled={loading}>
              {loading ? "Please wait..." : "Submit"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import { EditEmployeeSalaryModal } from "./EditEmployeeSalaryModal";

class Employee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditSalary: false,
    };
  }

  render() {
    const {
      _id,
      name,
      profile,
      displayImage,
      gender,
      userId,
      hasResigned,
      jobgrade,
      jobgradeid,
    } = this.props;
    const { openEditSalary } = this.state;

    const href = `${URLS.profile}/${_id}`;

    const profileImage = displayImage
      ? URLS.backendStatic + "/" + displayImage
      : "/assets/img/profiles/avatar-03.jpg";
    return (
      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
        <div className="profile-widget">
          <div className="profile-img">
            <Link to={href} className="avatar">
              <img
                src={profileImage}
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
            </Link>
          </div>
          <div className="dropdown profile-action p-cursor">
            <div
              className="action-icon dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <div
                className="dropdown-item"
                data-toggle="modal"
                data-target="#edit_employee"
                onClick={() => this.props.onEdit(_id, userId)}
              >
                <i className="fa fa-pencil m-r-5"></i> Edit
              </div>
              <div
                className="dropdown-item"
                data-toggle="modal"
                data-target="#delete_employee"
                onClick={this.props.onDelete.bind(this, _id)}
              >
                <i className="fa fa-trash-o m-r-5"></i> Delete
              </div>
              <div
                className="dropdown-item"
                onClick={() => this.setState({ openEditSalary: true })}
              >
                <i className="fa fa-money m-r-5"></i> Edit Salary
              </div>
            </div>
          </div>
          <h4 className="user-name m-t-10 mb-0 text-ellipsis">
            <Link to={href}>
              {(name?.first ? name?.first : "") +
                " " +
                (name?.middle ? name?.middle : "") +
                " " +
                (name?.last ? name?.last : "")}
            </Link>
          </h4>
          <div className="small text-muted">{jobgrade}</div>
          {hasResigned && <div className="text-danger">Resigned</div>}
        </div>
        {openEditSalary && (
          <EditEmployeeSalaryModal
            show={openEditSalary}
            handleClose={() => this.setState({ openEditSalary: false })}
            user_id={_id}
            name={name}
            jobgradeid={jobgradeid}
          />
        )}
      </div>
    );
  }
}

export default Employee;

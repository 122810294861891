import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import AddEmployeeModal from "./AddEmployeeModal";
import EditEmployeeModal from "./EditEmployeeModal";
import Employee from "./Employee";
import { URLS } from "../urls";
import PieChartModel from "./PieChartModel";
import { branch_id } from "../../utils";
import ModalLoader from "../ModalLoader";

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.updateEmployeesList = this.updateEmployeesList.bind(this);
    this.state = {
      isLoading: true,
      employees: [], //update this whenever you add/update employee in backend.
      editEmployee: "", // id or index of the employee which is to be edited
      editUser: "",
      deleteEmployee: "",
      searchFilter: {
        id: "",
        name: "",
        department: "",
      },
      searchEmlpoyeeList: [],
      departments: [],
      employeesStatistics: {
        confirmed: 0,
        trainee: 0,
        probation: 0,
        internship: 0,
        gender: {
          Male: 0,
          Female: 0,
          Others: 0,
        },
      },
      tableMode: false,
      addEmployee: false,
    };
  }

  editEmployee = (_id, userId) => {
    this.setState({ editEmployee: _id, editUser: userId });
  };

  deleteEmployee = (_id) => {
    this.setState({ deleteEmployee: _id });
  };

  getEmployeeFromId = (_id) => {
    const { employees } = this.state;
    let employee = {};
    employees.forEach((e) => {
      if (e._id === _id) employee = e;
    });
    return employee;
  };
  getEditEmployeeDataFromId = (id) => {
    const { employees } = this.state;
    let employee = {};
    if (employees && employees.length)
      employees.forEach((e) => {
        if (e && e._id === id) {
          employee = e;
          employee.jobInfo.grade =
            employee.jobInfo.grade && employee.jobInfo.grade._id;
          employee.jobInfo.workLocation =
            employee.jobInfo.workLocation && employee.jobInfo.workLocation._id;
          employee.jobInfo.department =
            employee.jobInfo.department && employee.jobInfo.department._id;
        }
      });
    return employee;
  };

  getEditUserDataFromId = (id) => {
    const { users } = this.state;
    let user = {};
    if (users && users.length) {
      user = users.find((user) => user._id === id);
    }
    return user;
  };

  updateEmployeesList = (employee) => {
    let { employees } = this.state;
    const id = employee && employee._id;
    let isExists = false;
    for (let index = employees.length - 1; index >= 0; --index) {
      if (employees[index]._id === id) {
        employees.splice(index, 1);
        employees.splice(index, 0, employee);
        isExists = true;
        break;
      }
    }
    if (isExists) {
      this.setState({ employees: employees, searchEmlpoyeeList: employees });
    } else {
      const updatedEmployees = employees.concat(employee);
      this.setState({
        employees: updatedEmployees,
        searchEmlpoyeeList: updatedEmployees,
      });
    }
  };

  componentWillMount() {
    const { employeesStatistics } = this.state;
    const token = "dummy token";

    fetch(`${URLS.backendUsers}/getAllUsers/${branch_id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          users: result.users,
        });
      });

    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employees: result.employees,
          searchEmlpoyeeList: result.employees,
        });
        if (result.employees && result.employees.length) {
          result.employees.forEach((employee) => {
            if (employee.jobInfo.status === "Confirmed")
              employeesStatistics.confirmed += 1;
            else if (employee.jobInfo.status === "Trainee")
              employeesStatistics.trainee += 1;
            else if (employee.jobInfo.status === "Probation")
              employeesStatistics.probation += 1;
            else if (employee.jobInfo.status === "Internship")
              employeesStatistics.internship += 1;

            if (employee.personalInfo.gender)
              employeesStatistics.gender[employee.personalInfo.gender] += 1;
          });
          this.setState({
            employeesStatistics,
          });
        }
      });
    fetch(`${URLS.backendDepartments}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          departments: result.departments,
        });
      });

    this.setState({
      isLoading: false,
    });
  }
  updateSearchFilterID = (evt) => {
    const { searchFilter } = this.state;
    searchFilter.id = evt.target.value;
    this.setState({
      searchFilter,
    });
  };
  updateSearchFilterName = (evt) => {
    const { searchFilter } = this.state;
    searchFilter.name = evt.target.value;
    this.setState({
      searchFilter,
    });
  };
  updateSearchFilterDepartment = (evt) => {
    const { searchFilter } = this.state;
    searchFilter.department = evt.target.value;
    this.setState({
      searchFilter,
    });
  };
  searchEmploees = () => {
    const { searchFilter, employees } = this.state;
    if (searchFilter.id && searchFilter.id !== "") {
      let tempEmlpoyeeList = employees.filter((employee) => {
        return employee.jobInfo.employeeId === searchFilter.id;
      });
      this.setState({
        searchEmlpoyeeList: tempEmlpoyeeList,
      });
    } else {
      if (searchFilter.name && searchFilter.name !== "") {
        let name = searchFilter.name.toLowerCase();
        let tempEmlpoyeeList = employees.filter(function (employee) {
          let employeeName =
            employee.personalInfo.name.first +
            employee.personalInfo.name.middle +
            employee.personalInfo.name.last;
          return employeeName.toLowerCase().indexOf(name) > -1;
        });
        if (searchFilter.department && searchFilter.department !== "") {
          tempEmlpoyeeList = tempEmlpoyeeList.filter(function (employee) {
            return employee.jobInfo.department
              ? employee.jobInfo.department._id === searchFilter.department
              : null;
          });
        }
        this.setState({
          searchEmlpoyeeList: tempEmlpoyeeList,
        });
      } else if (searchFilter.department && searchFilter.department !== "") {
        let tempEmlpoyeeList = employees.filter(function (employee) {
          return employee.jobInfo.department
            ? employee.jobInfo.department._id === searchFilter.department
            : null;
        });
        this.setState({
          searchEmlpoyeeList: tempEmlpoyeeList,
        });
      } else {
        this.setState({
          searchEmlpoyeeList: employees,
        });
      }
    }
  };

  handleDeleteEmployee = () => {
    let employees = this.state.employees;
    const employeeId = this.state.deleteEmployee;
    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/users/Employee/${employeeId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ EmployeeID: employeeId }),
      }).then((res) => {
        if (res.ok) {
          fetch(`${URLS.backendEmployees}/delete`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ employeeId }),
          })
            .then((res) => res.json())
            .then((result) => {
              employees = employees.filter(
                (employee) => employee._id !== employeeId
              );
              this.setState({ employees, searchEmlpoyeeList: employees });
            });
        } else {
          res.json().then((responseJson) => {
            console.log(responseJson);
            alert(responseJson.message);
          });
        }
      });
    } else {
      fetch(`${URLS.backendEmployees}/delete`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ employeeId }),
      })
        .then((res) => res.json())
        .then((result) => {
          employees = employees.filter(
            (employee) => employee._id !== employeeId
          );
          this.setState({ employees, searchEmlpoyeeList: employees });
        });
    }
    window.$("#delete_employee").modal("toggle");
  };

  render() {
    const {
      editEmployee,
      editUser,
      searchEmlpoyeeList,
      departments,
      searchFilter,
      employeesStatistics,
      isLoading,
    } = this.state;
    const editEmployeeData = this.getEditEmployeeDataFromId(editEmployee);
    const editUserData = this.getEditUserDataFromId(editUser);

    return (
      <>
        {isLoading ? (
          <ModalLoader show={true} />
        ) : (
          <body>
            <Helmet>
              <meta charSet="utf-8" />
              <meta name="robots" content="noindex, nofollow" />
              <title>Invex ERP</title>
            </Helmet>
            <div className="main-wrapper">
              <Topbar />
              <Sidebar />
              {/*<!-- Page Wrapper -->*/}
              <div className="page-wrapper">
                {/*<!-- Page Content -->*/}
                <div className="content container-fluid">
                  {/*<!-- Page Title -->*/}
                  <div className="row">
                    <div className="col">
                      <Breadcrumb>
                        <BreadcrumbItem>
                          <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Employees</BreadcrumbItem>
                      </Breadcrumb>
                      {/* <h4 className="page-title">Employee</h4> */}
                    </div>
                    <div className="col-auto text-right float-right ml-auto m-b-30">
                      {/* <a
                    href="#"
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_employee"
                  >
                    <i className="fa fa-plus"></i>Add Employee
                  </a> */}
                      <button
                        data-toggle="modal"
                        data-target="#add_employee"
                        className="btn add-btn"
                        onClick={() =>
                          this.setState({ ...this.state, addEmployee: true })
                        }
                      >
                        <i className="fa fa-plus"></i>Add Employee
                      </button>
                      <div className="view-icons">
                        <button
                          className="grid-view btn btn-link active"
                          onClick={() =>
                            this.setState({ ...this.state, tableMode: false })
                          }
                        >
                          <i className="fa fa-th"></i>
                        </button>
                        <button
                          className="list-view btn btn-link"
                          onClick={() =>
                            this.setState({ ...this.state, tableMode: true })
                          }
                        >
                          <i className="fa fa-bars"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*<!-- /Page Title -->*/}
                  {/*<!-- Leave Statistics -->*/}
                  <section className="stats">
                    {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Confirmed</h6>
                    <h4>{employeesStatistics.confirmed}</h4>
                  </div>
                </div> */}
                    <div className="stat">
                      <div className="icon icon-1">
                        <i class="fa fa-cubes"></i>
                      </div>
                      <div className="details">
                        <p>{employeesStatistics.confirmed}</p>
                        <span className="dashboard-links">Confirmed </span>
                      </div>
                    </div>

                    {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Trainee</h6>
                    <h4>{employeesStatistics.trainee}</h4>
                  </div>
                </div> */}
                    <div className="stat">
                      <div className="icon icon-3">
                        <i class="fa fa-cubes"></i>
                      </div>
                      <div className="details">
                        <p>{employeesStatistics.trainee}</p>
                        <span className="dashboard-links">Trainee </span>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Probation</h6>
                    <h4>{employeesStatistics.probation}</h4>
                  </div>
                </div> */}
                    <div className="stat">
                      <div className="icon">
                        <i class="fa fa-cubes"></i>
                      </div>
                      <div className="details">
                        <p>{employeesStatistics.probation}</p>
                        <span className="dashboard-links">Probation</span>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Internship</h6>
                    <h4>{employeesStatistics.internship}</h4>
                  </div>
                </div> */}
                    <div className="stat">
                      <div className="icon">
                        <i class="fa fa-cubes"></i>
                      </div>
                      <div className="details">
                        <p>{employeesStatistics.internship}</p>
                        <span className="dashboard-links">Internship</span>
                      </div>
                    </div>
                  </section>
                  {/*<!-- /Leave Statistics -->*/}
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{
                        textAlign: "center",
                        textAlign: "-webkit-center",
                      }}
                    >
                      <PieChartModel
                        data={[
                          {
                            name: "Male",
                            value: employeesStatistics["gender"]["Male"],
                          },
                          {
                            name: "Female",
                            value: employeesStatistics["gender"]["Female"],
                          },
                          {
                            name: "Others",
                            value: employeesStatistics["gender"]["Others"],
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {/*<!-- Search Filter -->*/}
                  <div className="row filter-row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value={searchFilter.id}
                          onChange={this.updateSearchFilterID}
                        />
                        <label className="focus-label">Employee ID</label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value={searchFilter.name}
                          onChange={this.updateSearchFilterName}
                        />
                        <label className="focus-label">Employee Name</label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group form-focus">
                        <Form.Control
                          as="select"
                          value={searchFilter.department}
                          onChange={this.updateSearchFilterDepartment}
                        >
                          <option value="">Select Department</option>
                          {departments && departments.length
                            ? departments.map((department) => {
                                return (
                                  <option value={department._id}>
                                    {department.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Control>
                        <label className="focus-label">Department</label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <button
                        className="btn add-btn btn-block"
                        onClick={this.searchEmploees}
                      >
                        {" "}
                        Search{" "}
                      </button>
                    </div>
                  </div>
                  {/* <div className="row">
								<div className="col">
									Filters
								</div>
							</div> */}
                  {/*<!-- Search Filter -->*/}

                  {/* {console.log(searchEmlpoyeeList)} */}
                  <div>
                    {this.state.tableMode ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table table-striped custom-table mb-0 datatable">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>EmployeeId</th>

                                  <th>Job Grade</th>
                                  <th>Email</th>
                                  <th>Mobile</th>
                                  <th>Join Date</th>
                                  <th>Start Date</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </thead>

                              <tbody>
                                {searchEmlpoyeeList &&
                                searchEmlpoyeeList.length ? (
                                  searchEmlpoyeeList.map((employee, index) => {
                                    employee = employee || {
                                      personalInfo: "",
                                      jobInfo: "",
                                    };

                                    const displayImage =
                                      employee?.emergencyInfo &&
                                      employee?.emergencyInfo?.image;

                                    const profileImage = displayImage
                                      ? URLS.backendStatic + "/" + displayImage
                                      : "/assets/img/profiles/avatar-03.jpg";

                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <h2 className="table-avatar blue-link">
                                            <Link
                                              to={`profile/${employee._id}`}
                                              className="avatar"
                                            >
                                              <img alt="" src={profileImage} />
                                            </Link>
                                            <Link
                                              to={`profile/${employee._id}`}
                                            >
                                              {employee.personalInfo?.name
                                                ?.first +
                                                " " +
                                                employee.personalInfo?.name
                                                  ?.last}
                                            </Link>
                                          </h2>
                                        </td>
                                        <td>{employee.jobInfo?.employeeId}</td>
                                        <td>{employee.jobInfo?.grade?.name}</td>
                                        <td>{employee.personalInfo?.email}</td>
                                        <td>
                                          {employee.personalInfo?.phone?.home}
                                        </td>
                                        <td>{employee.jobInfo?.joinDate}</td>
                                        <td>{employee.jobInfo?.startDate}</td>
                                        <td className="text-right">
                                          <div className="dropdown dropdown-action">
                                            <a
                                              href="#"
                                              className="action-icon dropdown-toggle"
                                              data-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="material-icons">
                                                more_vert
                                              </i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#edit_employee"
                                                onClick={() => {
                                                  this.editEmployee(
                                                    employee._id,
                                                    employee.userId
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-pencil m-r-5"></i>{" "}
                                                Edit
                                              </a>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#delete_employee"
                                                onClick={() => {
                                                  this.deleteEmployee(
                                                    employee._id
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-trash-o m-r-5"></i>{" "}
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <h6
                                    style={{
                                      textAlign: "center",
                                      width: "100%",
                                    }}
                                  >
                                    No employee found
                                  </h6>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {this.state.isLoading ? (
                          <ModalLoader show={true} />
                        ) : (
                          <div className="row staff-grid-row">
                            {searchEmlpoyeeList && searchEmlpoyeeList.length ? (
                              searchEmlpoyeeList.map((employee) => {
                                employee = employee || {
                                  personalInfo: "",
                                  jobInfo: "",
                                };

                                let em = null;
                                if (
                                  !employee.hasResigned &&
                                  !employee.isTerminated
                                ) {
                                  em = (
                                    <Employee
                                      key={employee._id}
                                      _id={employee._id}
                                      userId={employee.userId}
                                      name={employee.personalInfo.name}
                                      profile={employee.jobInfo.title}
                                      jobgrade={employee.jobInfo?.grade?.name}
                                      gender={employee.personalInfo.gender}
                                      onEdit={(_id, userId) =>
                                        this.editEmployee(_id, userId)
                                      }
                                      onDelete={this.deleteEmployee}
                                      displayImage={
                                        employee.emergencyInfo &&
                                        employee.emergencyInfo.image
                                      }
                                      hasResigned={employee.hasResigned}
                                      jobgradeid={employee.jobInfo?.grade?._id}
                                    />
                                  );
                                }

                                return em;
                              })
                            ) : (
                              <h6
                                style={{ textAlign: "center", width: "100%" }}
                              >
                                No employee found
                              </h6>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/*<!-- /Page Content -->*/}

                {/*<!-- Add Employee Modal -->*/}
                {this.state.addEmployee && (
                  <AddEmployeeModal
                    mode="add"
                    updateEmployeesList={this.updateEmployeesList.bind(this)}
                    hindeIt={() =>
                      this.setState({ ...this.state, addEmployee: false })
                    }
                  />
                )}
                {/*<!-- /Add Employee Modal -->*/}

                {/*<!-- Edit Employee Modal -->*/}
                <EditEmployeeModal
                  mode="edit"
                  employee={editEmployeeData}
                  user={editUserData}
                  updateEmployeesList={this.updateEmployeesList}
                  employee_id={editEmployee}
                />
                {/* {pass correct employee in edit employee modal. use editEmployee} */}
                {/*<!-- /Edit Employee Modal -->*/}

                {/*<!-- Delete Employee Modal -->*/}
                <div
                  className="modal custom-modal fade"
                  id="delete_employee"
                  role="dialog"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="form-header">
                          <h3>Delete Employee</h3>
                          <p>Are you sure want to delete?</p>
                        </div>
                        <div className="modal-btn delete-action">
                          <div className="row">
                            <div className="col-6">
                              <button
                                className="btn btn-primary continue-btn"
                                onClick={this.handleDeleteEmployee}
                              >
                                Delete
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                data-dismiss="modal"
                                className="btn btn-primary cancel-btn"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<!-- /Delete Employee Modal -->*/}
              </div>
              {/*<!-- /Page Wrapper -->*/}
            </div>
            {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
            <div className="sidebar-overlay" data-reff=""></div>
          </body>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Employees));

import React from "react";
import { URLS } from "../urls";
import moment from "moment";
import DatePicker from "react-datetime";
import { Form, Col, Row, Image } from "react-bootstrap";
import { branch_id } from "../../utils";

export default class ApplyLeaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      applyLeave: {
        employee: {
          id: null,
          name: "",
          image: "",
          designation: "",
        },
        name: "",
        from: moment(new Date()).format("DD/MM/YYYY"),
        to: moment(new Date()).format("DD/MM/YYYY"),
        days: 1,
        remaining: 0,
        reason: "",
        status: "New",
        appliedDate: "",
        branch_id,
      },
      leaveTypes: [],
    };
  }
  componentWillReceiveProps(props) {
    if (props.leaveTypes.length)
      this.setState({
        leaveTypes: props.leaveTypes,
      });
    if (props.employee) {
      const { applyLeave } = this.state;
      applyLeave.employee = props.employee;
      this.setState({
        applyLeave,
      });
    }
  }
  updateLeaveName = (e) => {
    const { applyLeave } = this.state;
    applyLeave.name = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveFrom = (event) => {
    const { applyLeave } = this.state;
    applyLeave.from = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      applyLeave,
    });
  };
  updateLeaveTo = (event) => {
    const { applyLeave } = this.state;
    applyLeave.to = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    applyLeave.days =
      moment(applyLeave.to, "DD/MM/YYYY").diff(
        moment(applyLeave.from, "DD/MM/YYYY"),
        "days"
      ) + 1;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveDays = (e) => {
    const { applyLeave } = this.state;
    applyLeave.days = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveRemaining = (e) => {
    const { applyLeave } = this.state;
    applyLeave.remaining = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveReason = (e) => {
    const { applyLeave } = this.state;
    applyLeave.reason = e.target.value;
    this.setState({
      applyLeave,
    });
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const { applyLeave } = this.state;
    applyLeave.appliedDate = new Date().getTime();
    const token = "dummy token";
    fetch(`${URLS.backendApplyLeaves}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ applyLeave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          setTimeout(() => {
            window.$("#add_leave").modal("toggle");
            this.setState({ buttonText: "Submit", isWaiting: false });
            console.log("YOYO", result.applyLeave);
            this.props.updateAppliedLeavesList(result.applyLeave);
          }, 500);
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
          this.setState({ buttonText: "Submit", isWaiting: false });
        });
      }
    });
  };

  render() {
    const { applyLeave, leaveTypes, buttonText } = this.state;
    // const {employees} = this.props;
    return (
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Leave</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.submit}>
                <div className="form-group">
                  <label>
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    as="select"
                    value={applyLeave.name}
                    onChange={this.updateLeaveName}
                    style={{ height: "35px" }}
                  >
                    <option value="">Select Type</option>
                    {leaveTypes.length
                      ? leaveTypes.map((leaveType) => {
                          return (
                            <option value={leaveType.name}>
                              {leaveType.name}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Control>
                </div>
                <div className="form-group">
                  <label>
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      value={applyLeave.from}
                      closeOnSelect={true}
                      onChange={this.updateLeaveFrom}
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      value={applyLeave.to}
                      closeOnSelect={true}
                      onChange={this.updateLeaveTo}
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readonly
                    type="number"
                    value={applyLeave.days}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readonly
                    type="number"
                    value={applyLeave.remaining}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows="4"
                    className="form-control"
                    value={applyLeave.reason}
                    onChange={this.updateLeaveReason}
                  ></textarea>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">
                    {buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal } from "react-bootstrap";
import Select from "react-select";
import "../../assets/css/jobgrade.css";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { branch_id } from "../../utils";
import { fetchActionsUtil } from "../../utils";

export const EditEmployeeSalaryModal = (props) => {
  const [salary, setSalary] = useState({
    basic: 0,
    housing: 0,
    medical: 0,
    transport: 0,
    utility: 0,
    dressing: 0,
    entertainment: 0,
  });
  const [totalGrade, setTotalGrade] = useState(0);
  const [jobGrades, setJobGrade] = useState([]);
  const [totaljobGrades, setTptalJobGrade] = useState([]);
  const [salarySetting, setSalarySetting] = useState({});
  const [stopPercentage, setStopPercentage] = useState(true);
  const [singleGrade, setSingleGrade] = useState("");

  const token = "dummy token";

  const getSalaryByuser = async (id) => {
    const data = await fetchActionsUtil(
      `${URLS.backendemployeeSalary}/single/${id}`,
      "GET",
      token
    );
    data?.jobGrades && setSalary(data?.jobGrades?.salary);
  };

  const getSalaryByBranch = async (id) => {
    const data = await fetchActionsUtil(
      `${URLS.backendsalarySetting}/get/${id}`,
      "GET",
      token
    );
    if (data?.jobGrades?.length > 0) {
      const {
        basic,
        housing,
        medical,
        transport,
        utility,
        dressing,
        entertainment,
      } = data?.jobGrades[0];

      setSalarySetting({
        basic,
        housing,
        medical,
        transport,
        utility,
        dressing,
        entertainment,
      });
    }
  };

  const getJobgrade = async (id) => {
    const data = await fetchActionsUtil(
      `${URLS.backendJobGrades}/get/${id}`,
      "GET",
      token
    );
    if (data?.jobGrades) {
      const jobs = data?.jobGrades?.map((el) => ({
        label: el?.name,
        value: el?._id,
      }));
      const jobsTotals = data?.jobGrades?.map((el) => {
        const tol = Object.values(el?.salary).reduce(
          (p, c) => parseFloat(p) + parseFloat(c),
          0
        );
        return {
          id: el?._id,
          total: tol,
        };
      });
      setTptalJobGrade(jobsTotals);
      setJobGrade(jobs);
    }
  };

  useEffect(() => {
    getSalaryByuser(props.user_id);
    getSalaryByBranch(branch_id);
    getJobgrade(branch_id);
    setSingleGrade(props.jobgradeid);
  }, [props.user_id, props.jobgradeid]);

  useEffect(() => {
    if (stopPercentage) {
      const tol = Object.values(salary).reduce(
        (p, c) => parseFloat(p) + parseFloat(c),
        0
      );
      setTotalGrade(tol);
    }
  }, [salary, stopPercentage]);

  const updateSalaryInputs = (value) => {
    const grade = {};
    Object.entries({
      ...salarySetting,
    }).forEach((el) => {
      if (el[1] > 0) {
        const money = (parseFloat(el[1]) / 100) * parseFloat(value);
        grade[`${el[0]}`] = money;
      }
    });
    setSalary({ ...grade });
  };

  const calculatePercentage = (value) => {
    setTotalGrade(value);
    setStopPercentage(false);
    updateSalaryInputs(value);
  };

  const onChangeInputs = (name, value) => {
    setStopPercentage(true);
    setSalary({ ...salary, [name]: value });
  };

  const onSelectedGrade = (item) => {
    setSingleGrade(item);
    const slay = totaljobGrades.find((el) => el.id === item).total;
    updateSalaryInputs(slay);
    setTotalGrade(slay);
  };

  const submitJob = async (e) => {
    e.preventDefault();
    try {
      console.log("in submit");
      const grade = {};
      Object.entries(salary).forEach((el) => {
        grade[`${el[0]}`] = parseFloat(el[1]);
      });
      const jobGrade = {
        name: `${props?.name?.first} ${props?.name?.last}`,
        branch_id,
        salary: grade,
        totalGrade: parseFloat(totalGrade),
        userID: props.user_id,
        singleGrade,
      };
      // console.log(jobGrade);
      const result = await fetchActionsUtil(
        `${URLS.backendemployeeSalary}/add`,
        "POST",
        token,
        {
          jobGrade: jobGrade,
        }
      );

      if (result.success) {
        props.handleClose();

        toast("Salary updated successfully", {
          type: "success",
        });
      } else {
        const e = Error(result.err);
        console.log(e);
        toast("Unable to edit salary", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Edit {props?.name?.first} {props?.name?.last} Salary
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitJob} className="px-3 mt-3">
        <Form.Group as={Row}>
          {/*  */}
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Job Grade
          </Form.Label>
          <Col sm={8}>
            <Select
              closeMenuOnSelect={true}
              isSearchable={true}
              onChange={(selected) => {
                onSelectedGrade(selected.value);
              }}
              value={jobGrades.find((d) => singleGrade === d.value)}
              options={jobGrades}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          {/*  */}
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Basic
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={salary.basic}
              allowNegativeValue={false}
              name="basic"
              // allowDecimals={false}
              onValueChange={(value, name) => onChangeInputs(name, value)}
            />
          </Col>
          {/*  */}
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Housing
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={salary.housing}
              allowNegativeValue={false}
              name="housing"
              // allowDecimals={false}
              onValueChange={(value, name) => onChangeInputs(name, value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Medical
          </Form.Label>

          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={salary.medical}
              allowNegativeValue={false}
              name="medical"
              // allowDecimals={false}
              onValueChange={(value, name) => onChangeInputs(name, value)}
            />
          </Col>
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Transport
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={salary.transport}
              allowNegativeValue={false}
              name="transport"
              // allowDecimals={false}
              onValueChange={(value, name) => onChangeInputs(name, value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Utility
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={salary.utility}
              allowNegativeValue={false}
              name="utility"
              // allowDecimals={false}
              onValueChange={(value, name) => onChangeInputs(name, value)}
            />
          </Col>
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Dressing
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={salary.dressing}
              allowNegativeValue={false}
              name="dressing"
              // allowDecimals={false}
              onValueChange={(value, name) => onChangeInputs(name, value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            <b>GROSS SALARY</b>
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={totalGrade}
              allowNegativeValue={false}
              onValueChange={(value, name) => calculatePercentage(value)}
            />
          </Col>

          <Form.Label
            style={{ fontSize: "14px" }}
            column
            // sm={{ span: 2, offset: 6 }}
            sm={2}
          >
            Entertainment
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={salary.entertainment}
              allowNegativeValue={false}
              name="entertainment"
              // allowDecimals={false}
              onValueChange={(value, name) => onChangeInputs(name, value)}
            />
          </Col>
        </Form.Group>

        <div className="d-flex justify-content-center p-4">
          <button
            style={{
              borderRadius: "7px",
              backgroundColor: "#5378b2",
              border: "none",
            }}
            className="btn btn-primary submit-btn"
          >
            Update Salary
          </button>
        </div>
      </Form>
    </Modal>
  );
};
